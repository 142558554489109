import { SET_SCROLL } from "../actionTypes";

const initialState = {
    scroll: 0
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL: {
      return {
        ...state,
        scroll: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default common;
