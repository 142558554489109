export const getTags = store => {
    return store.filters.tags
}

export const getDisplayFilters = store => {
    return store.filters.displayFilters
}

export const getScroll = store => {
    return store.common.scroll
}
