import { useState, useEffect } from "react"
import { tagsToRender } from "../utils/constants"
import CustomCheckBox from "../components/CustomCheckBox"
import { connect } from "react-redux"
import { getTags, getDisplayFilters } from "../redux/selectors"
import { setTags, setDisplayFilters } from "../redux/actions"
import '../styles/Filter.css'

const Filter = (props) => {
    const [search, setSearch] = useState("")
    const recipesKeys = Object.keys(props.recipes)
    const checkBoxChange = (value, add) => {
        if (add) {
            props.setTags([...props.tags, value])
        } else {
            const newTags = props.tags.filter((tag) => tag !== value)
            props.setTags(newTags)
        }
    }

    useEffect(() => {
        const filteredRecipes = []

        recipesKeys.forEach((key) => {
            let showRecipe = true
            // Check tags
            props.tags.forEach((tag) => {
                if (!props.recipes[key].tags.includes(tag)) {
                    showRecipe = false
                }
            })

            // Check search
            if (search && !props.recipes[key].name.toUpperCase().includes(search.toUpperCase())) {
                showRecipe = false
            }

            if (showRecipe) {
                filteredRecipes.push(props.recipes[key])
            }
        })

        props.setFilteredRecipes(filteredRecipes)
    }, [search, props.recipes, props.tags])

    return (
      <div className="Filter">
          <div>
            <input type="text" placeholder="Hledat..." onChange={(e) => {
                setSearch(e.target.value)
            }}/>
            <button onClick={() => {props.setDisplayFilters(!props.showFilters)}}>Filtr</button>
          </div>
          {props.showFilters && (
            <div className="FiltersContainer">
                {tagsToRender.map((tag) => (
                    <CustomCheckBox
                        label={tag}
                        onChange={checkBoxChange}
                        key={tag}
                        checked={props.tags.includes(tag)}
                    />
                ))}
            </div>
          )}
      </div>
    );
  }

  const mapStateToProps = state => {
    return {
        tags: getTags(state),
        showFilters: getDisplayFilters(state),
    }
  }
  
export default connect(
    mapStateToProps,
    {
        setTags,
        setDisplayFilters,
    }
)(Filter);
