export const tagsToRender = [
    "Hlavní Chod",
    "Malé Jídlo",
    "Kuře",
    "Hovězí",
    "Vepřové",
    "Těstoviny",
    "Brambory",
    "Rýže",
    "Ryby",
    "Sladké",
    "Zdravé",
    "Příloha"
]
