import '../styles/CustomCheckBox.css';

const CustomCheckBox = (props) => {
 return (
        <label className="CustomCheckBox"> {props.label}
            <input checked={props.checked} type="checkbox" onChange={(e) => {
                props.onChange(props.label, e.target.checked)
            }}/>
            <span className="checkmark"></span>
        </label>
    );
  }
  
export default CustomCheckBox;
