import { SET_TAGS, SET_DISPLAY_FILTERS, SET_SCROLL } from "./actionTypes";

export const setTags = tags => ({
    type: SET_TAGS,
    payload: tags
});

export const setDisplayFilters = display => ({
    type: SET_DISPLAY_FILTERS,
    payload: display
});

export const setScroll = scroll => ({
  type: SET_SCROLL,
  payload: scroll
});
