import '../styles/Recipe.css';
import { useState, useEffect } from "react"
import BackButton from "../components/BackButton"
import firebase from "firebase/app";

require('firebase/database');

const Recipe = (props) => {
  const id = props?.match?.params?.id
  const [recipe, setRecipe] = useState(null)
  const path = "recipes/" + id

  useEffect(() => {
    firebase.database().ref().child(path).once("value", recipe => {
      if (!recipe.val && !recipe) {
        return
      }
      setRecipe(recipe.val())
    })
  }, [])

  if (!recipe) {
    return (
      <div>Načítám...</div>
    )
  }

  const src = "https://res.cloudinary.com/dvd0muryl/image/upload/v1610781792/" + recipe.thumb

  return (
      <div className="RecipeContainer">
        <BackButton/>
        <div className="ImageContainer">
          <img className="RecipeImage" alt={recipe.name} src={recipe.thumb.includes("http") ? recipe.thumb : src}/>
          <div className="RecipeNameContainer">
            <h1 className="RecipeName">{recipe.name}</h1>
          </div>
        </div>
        <div className="RecipePart">
          <h2>Suroviny:</h2>
          <ul>
            {recipe.ingredients.map((ingredient) => {
              return (
                <li key={ingredient.name} className="RecipeListItem">{ingredient.amount} {ingredient.name}</li>
              )
            })}
          </ul>
        </div>
        <div className="RecipePart">
          <h2>Postup:</h2>
          <ul>
            {recipe.procedure.map((step) => {
              return (
                <li key={step} className="RecipeListItem">{step}</li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  }
  
  export default Recipe;
  