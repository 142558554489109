import AddRecipe from "../components/AddRecipe"
import AuthDialog from "../components/AuthDialog"
import { useState } from "react"

const Admin = () => {
  const [logedIn, setLogedIn] = useState(false)

  const onLogin = () => {
    setLogedIn(true)
  }

  return (
    <div>
      {!logedIn && (
        <AuthDialog onLogin={onLogin}/>
      )}
      {logedIn && (
        <div>
          <AddRecipe/>
        </div>
      )}
    </div>
  );
}
  
export default Admin;
