import '../styles/AuthDialog.css';
import { useState } from "react"
import firebase from "firebase/app";
require('firebase/auth')

const AuthDialog = (props) => {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [errorMesage, setErrorMesage] = useState("")

    const login = () => {
        if (!userName || !password) {
            return
        }
        firebase.auth().signInWithEmailAndPassword(userName, password).then((response) => {
            setErrorMesage("")
            props.onLogin()
        }).catch((error) => {
            setErrorMesage("Nastal problém s přihlášením, pokud jsou zadané údaje správné kontaktuj Dana.")
        })
    }

    return (
        <div className="AuthContainer"  onKeyDown={(event) => {
            if (event.code === 'Enter') {
                login()
            }
        }}>
            {
                errorMesage && (
                    <h3>{errorMesage}</h3>
                )
            }
            <label>Email</label>
            <input type="email" onChange={(e) => {
                setUserName(e.target.value)
            }}/>
            <label>Heslo</label>
            <input type="password" onChange={(e) => {
                setPassword(e.target.value)
            }}/>
            <button onClick={login}>Přihlásit</button>
        </div>
    );
}
  
export default AuthDialog;
