import '../styles/BackButton.css';
import {Link} from "react-router-dom";

const BackButton = () => {
    return (
        <Link
            style={{ textDecoration: 'none' }}
            to={"/"}
            className="BackButton"
        >
            Zpět
        </Link>
    )
  }
  
export default BackButton;
