import {Link} from "react-router-dom";
import '../styles/RecipeShort.css';

const RecipeShort = (props) => {
    if (!props.recipe) {
        return null
    }
    const isSmallScrenn = window.screen.width < 500
    const {name, thumb, id} = props.recipe
    const src = "https://res.cloudinary.com/dvd0muryl/image/upload/v1610781792/" + thumb

    if (isSmallScrenn) {
      return (
        <div className="Container">
          <Link to={"/recipe/" + id} style={{ textDecoration: 'none' }}>
            <h1 className="Text">{name}</h1>
            <img className="Image" src={thumb.includes("http") ? thumb : src} alt={name}/>
          </Link>
        </div>
      );
    }

    return (
      <div onClick={(e) => {
        props.setScroll(document.scrollingElement.scrollTop)
      }} className="Container">
        <Link to={"/recipe/" + id}>
          <img className="Image" src={thumb.includes("http") ? thumb : src} alt={name}/>
          <div className="NameContainer">
            <h1 className="Text">{name}</h1>
          </div>
        </Link>
      </div>
    );
  }
  
export default RecipeShort;
