import { SET_TAGS, SET_DISPLAY_FILTERS } from "../actionTypes";

const initialState = {
  tags: [],
  displayFilters: false
};

const filters = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAGS: {
      return {
        ...state,
        tags: action.payload,
      };
    }
    case SET_DISPLAY_FILTERS: {
      return {
        ...state,
        displayFilters: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default filters;
