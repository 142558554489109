import '../styles/RecipesList.css';
import { useState, useEffect } from "react"
import RecipeShort from "../components/RecipeShort"
import Filter from "../components/Filter"
import firebase from "firebase/app"
import { connect } from "react-redux"
import { getScroll } from "../redux/selectors"
import { setScroll } from "../redux/actions"
import { isMobileOrTablet } from "../utils/functions"
require('firebase/database')

const RecipesList = (props) => {
  const [recipes, setRecipes] = useState([])
  const [filteredRecipes, setFilteredRecipes] = useState([])

  useEffect(() => {
    firebase.database().ref().child("recipes/").once("value", rcps => {
      if (!rcps.val && !recipes) {
        return
      }
      setRecipes(rcps.val())
    })
  }, [])

  useEffect(() => {
    if (!isMobileOrTablet()) {
      window.setTimeout(() => {
        window.scroll(0, props.scroll)
      }, 200)
    }
  }, [])

  if (!recipes) {
    return (
      <div>Načítám...</div>
    )
  }

  return (
    <div className="RecipesContainer">
      <Filter recipes={recipes} setFilteredRecipes={setFilteredRecipes}/>
      {
        filteredRecipes && filteredRecipes.map((recipe) => (
          <RecipeShort setScroll={props.setScroll} recipe={recipe} key={recipe.name} />
        ))
      }
    </div>
  );
}

const mapStateToProps = state => {
  return {
      scroll: getScroll(state),
  }
}

export default connect(
  mapStateToProps,
  { setScroll }
)(RecipesList);
